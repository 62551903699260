*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --ring: transparent;
  }

  .dark {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --ring: transparent;
  }
  * {
    border-color: hsl(var(--border));
}

  body {
    background-color: hsl(var(--background));
    color: hsl(var(--foreground));
}
.tw-pointer-events-none {
    pointer-events: none;
}
.tw-fixed {
    position: fixed;
}
.tw-absolute {
    position: absolute;
}
.tw-relative {
    position: relative;
}
.tw-inset-0 {
    inset: 0px;
}
.tw-left-\[50\%\] {
    left: 50%;
}
.tw-right-0 {
    right: 0px;
}
.tw-top-0 {
    top: 0px;
}
.tw-top-\[50\%\] {
    top: 50%;
}
.tw-z-10 {
    z-index: 10;
}
.tw-z-50 {
    z-index: 50;
}
.tw-col-span-3 {
    grid-column: span 3 / span 3;
}
.tw-float-right {
    float: right;
}
.tw-m-0 {
    margin: 0px;
}
.tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.tw-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}
.tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.tw-ml-0\.5 {
    margin-left: 0.125rem;
}
.tw-mr-0 {
    margin-right: 0px;
}
.tw-mt-2 {
    margin-top: 0.5rem;
}
.tw-mt-4 {
    margin-top: 1rem;
}
.tw-mt-\[-12px\] {
    margin-top: -12px;
}
.tw-mt-\[-2px\] {
    margin-top: -2px;
}
.tw-flex {
    display: flex;
}
.tw-inline-flex {
    display: inline-flex;
}
.tw-grid {
    display: grid;
}
.tw-h-10 {
    height: 2.5rem;
}
.tw-h-11 {
    height: 2.75rem;
}
.tw-h-4 {
    height: 1rem;
}
.tw-h-5 {
    height: 1.25rem;
}
.tw-h-6 {
    height: 1.5rem;
}
.tw-h-9 {
    height: 2.25rem;
}
.tw-h-\[1px\] {
    height: 1px;
}
.tw-h-full {
    height: 100%;
}
.tw-max-h-\[1080px\] {
    max-height: 1080px;
}
.tw-min-h-\[1080px\] {
    min-height: 1080px;
}
.tw-min-h-\[400px\] {
    min-height: 400px;
}
.tw-min-h-dvh {
    min-height: 100dvh;
}
.tw-w-1 {
    width: 0.25rem;
}
.tw-w-10 {
    width: 2.5rem;
}
.tw-w-20 {
    width: 5rem;
}
.tw-w-4 {
    width: 1rem;
}
.tw-w-6 {
    width: 1.5rem;
}
.tw-w-\[100px\] {
    width: 100px;
}
.tw-w-\[120px\] {
    width: 120px;
}
.tw-w-\[140px\] {
    width: 140px;
}
.tw-w-\[160px\] {
    width: 160px;
}
.tw-w-\[180px\] {
    width: 180px;
}
.tw-w-\[1px\] {
    width: 1px;
}
.tw-w-\[48px\] {
    width: 48px;
}
.tw-w-\[52px\] {
    width: 52px;
}
.tw-w-full {
    width: 100%;
}
.tw-w-px {
    width: 1px;
}
.tw-min-w-\[1920px\] {
    min-width: 1920px;
}
.tw-max-w-\[1920px\] {
    max-width: 1920px;
}
.tw-max-w-lg {
    max-width: 32rem;
}
.tw-shrink-0 {
    flex-shrink: 0;
}
.tw-grow {
    flex-grow: 1;
}
.tw-basis-1\/2 {
    flex-basis: 50%;
}
.tw-basis-1\/3 {
    flex-basis: 33.333333%;
}
.tw-basis-1\/4 {
    flex-basis: 25%;
}
.tw-table-fixed {
    table-layout: fixed;
}
.tw-caption-bottom {
    caption-side: bottom;
}
.tw-translate-x-\[-50\%\] {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-y-\[-50\%\] {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes tw-caret-blink {
    0%,70%,100% {
        opacity: 1;
    }
    20%,50% {
        opacity: 0;
    }
}
.tw-animate-caret-blink {
    animation: tw-caret-blink 1.25s ease-out infinite;
}
@keyframes tw-spin {
    to {
        transform: rotate(360deg);
    }
}
.tw-animate-spin {
    animation: tw-spin 1s linear infinite;
}
.tw-cursor-pointer {
    cursor: pointer;
}
.tw-select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.tw-flex-row {
    flex-direction: row;
}
.tw-flex-col {
    flex-direction: column;
}
.tw-flex-col-reverse {
    flex-direction: column-reverse;
}
.tw-items-start {
    align-items: flex-start;
}
.tw-items-center {
    align-items: center;
}
.tw-items-stretch {
    align-items: stretch;
}
.tw-justify-end {
    justify-content: flex-end;
}
.tw-justify-center {
    justify-content: center;
}
.tw-justify-between {
    justify-content: space-between;
}
.tw-gap-0 {
    gap: 0px;
}
.tw-gap-1 {
    gap: 0.25rem;
}
.tw-gap-2 {
    gap: 0.5rem;
}
.tw-gap-3 {
    gap: 0.75rem;
}
.tw-gap-4 {
    gap: 1rem;
}
.tw-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.tw-whitespace-nowrap {
    white-space: nowrap;
}
.tw-rounded-lg {
    border-radius: var(--radius);
}
.tw-rounded-md {
    border-radius: calc(var(--radius) - 2px);
}
.tw-rounded-sm {
    border-radius: calc(var(--radius) - 4px);
}
.\!tw-border {
    border-width: 1px !important;
}
.tw-border {
    border-width: 1px;
}
.tw-border-y {
    border-top-width: 1px;
    border-bottom-width: 1px;
}
.tw-border-b {
    border-bottom-width: 1px;
}
.tw-border-r {
    border-right-width: 1px;
}
.tw-border-t {
    border-top-width: 1px;
}
.tw-border-solid {
    border-style: solid;
}
.tw-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
.tw-border-input {
    border-color: hsl(var(--input));
}
.tw-border-primary {
    border-color: hsl(var(--primary));
}
.tw-bg-\[\#f4f6f8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 246 248 / var(--tw-bg-opacity, 1));
}
.tw-bg-background {
    background-color: hsl(var(--background));
}
.tw-bg-black\/80 {
    background-color: rgb(0 0 0 / 0.8);
}
.tw-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}
.tw-bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}
.tw-bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}
.tw-bg-border {
    background-color: hsl(var(--border));
}
.tw-bg-destructive {
    background-color: hsl(var(--destructive));
}
.tw-bg-foreground {
    background-color: hsl(var(--foreground));
}
.tw-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}
.tw-bg-muted\/50 {
    background-color: hsl(var(--muted) / 0.5);
}
.tw-bg-primary {
    background-color: hsl(var(--primary));
}
.tw-bg-secondary {
    background-color: hsl(var(--secondary));
}
.\!tw-p-0 {
    padding: 0px !important;
}
.tw-p-0 {
    padding: 0px;
}
.tw-p-1 {
    padding: 0.25rem;
}
.tw-p-2 {
    padding: 0.5rem;
}
.tw-p-4 {
    padding: 1rem;
}
.tw-p-6 {
    padding: 1.5rem;
}
.tw-px-0 {
    padding-left: 0px;
    padding-right: 0px;
}
.tw-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.tw-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.tw-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.tw-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}
.tw-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.tw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.tw-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.tw-pb-4 {
    padding-bottom: 1rem;
}
.tw-pl-3 {
    padding-left: 0.75rem;
}
.tw-pr-1 {
    padding-right: 0.25rem;
}
.tw-pr-3 {
    padding-right: 0.75rem;
}
.tw-pt-2 {
    padding-top: 0.5rem;
}
.tw-pt-4 {
    padding-top: 1rem;
}
.tw-text-left {
    text-align: left;
}
.tw-text-center {
    text-align: center;
}
.tw-text-right {
    text-align: right;
}
.tw-align-middle {
    vertical-align: middle;
}
.tw-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.tw-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.tw-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.tw-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.tw-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.tw-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.tw-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.tw-font-bold {
    font-weight: 700;
}
.tw-font-medium {
    font-weight: 500;
}
.tw-font-normal {
    font-weight: 400;
}
.tw-font-semibold {
    font-weight: 600;
}
.tw-italic {
    font-style: italic;
}
.tw-leading-none {
    line-height: 1;
}
.tw-text-\[\#333\] {
    --tw-text-opacity: 1;
    color: rgb(51 51 51 / var(--tw-text-opacity, 1));
}
.tw-text-\[\#666\] {
    --tw-text-opacity: 1;
    color: rgb(102 102 102 / var(--tw-text-opacity, 1));
}
.tw-text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.tw-text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}
.tw-text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}
.tw-text-current {
    color: currentColor;
}
.tw-text-destructive-foreground {
    color: hsl(var(--destructive-foreground));
}
.tw-text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}
.tw-text-muted-foreground {
    color: hsl(var(--muted-foreground));
}
.tw-text-primary {
    color: hsl(var(--primary));
}
.tw-text-primary-foreground {
    color: hsl(var(--primary-foreground));
}
.tw-text-secondary-foreground {
    color: hsl(var(--secondary-foreground));
}
.tw-underline-offset-4 {
    text-underline-offset: 4px;
}
.tw-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.tw-ring-ring {
    --tw-ring-color: hsl(var(--ring));
}
.tw-ring-offset-background {
    --tw-ring-offset-color: hsl(var(--background));
}
.tw-transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-duration-1000 {
    transition-duration: 1000ms;
}
.tw-duration-200 {
    transition-duration: 200ms;
}
.tw-duration-300 {
    transition-duration: 300ms;
}
.tw-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes enter {
    from {
        opacity: var(--tw-enter-opacity, 1);
        transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
    }
}
@keyframes exit {
    to {
        opacity: var(--tw-exit-opacity, 1);
        transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
    }
}
.tw-duration-1000 {
    animation-duration: 1000ms;
}
.tw-duration-200 {
    animation-duration: 200ms;
}
.tw-duration-300 {
    animation-duration: 300ms;
}
.tw-ease-in-out {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.file\:tw-border-0::file-selector-button {
    border-width: 0px;
}
.file\:tw-bg-transparent::file-selector-button {
    background-color: transparent;
}
.file\:tw-text-sm::file-selector-button {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.file\:tw-font-medium::file-selector-button {
    font-weight: 500;
}
.placeholder\:tw-text-muted-foreground::-moz-placeholder {
    color: hsl(var(--muted-foreground));
}
.placeholder\:tw-text-muted-foreground::placeholder {
    color: hsl(var(--muted-foreground));
}
.first\:tw-rounded-l-md:first-child {
    border-top-left-radius: calc(var(--radius) - 2px);
    border-bottom-left-radius: calc(var(--radius) - 2px);
}
.first\:tw-border-l:first-child {
    border-left-width: 1px;
}
.last\:tw-rounded-r-md:last-child {
    border-top-right-radius: calc(var(--radius) - 2px);
    border-bottom-right-radius: calc(var(--radius) - 2px);
}
.hover\:tw-bg-accent:hover {
    background-color: hsl(var(--accent));
}
.hover\:tw-bg-destructive\/90:hover {
    background-color: hsl(var(--destructive) / 0.9);
}
.hover\:tw-bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
.hover\:tw-bg-muted\/50:hover {
    background-color: hsl(var(--muted) / 0.5);
}
.hover\:tw-bg-primary\/90:hover {
    background-color: hsl(var(--primary) / 0.9);
}
.hover\:tw-bg-secondary\/80:hover {
    background-color: hsl(var(--secondary) / 0.8);
}
.hover\:tw-text-accent-foreground:hover {
    color: hsl(var(--accent-foreground));
}
.hover\:tw-underline:hover {
    text-decoration-line: underline;
}
.focus-visible\:tw-outline-none:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus-visible\:tw-ring-2:focus-visible {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:tw-ring-ring:focus-visible {
    --tw-ring-color: hsl(var(--ring));
}
.focus-visible\:tw-ring-offset-2:focus-visible {
    --tw-ring-offset-width: 2px;
}
.disabled\:tw-pointer-events-none:disabled {
    pointer-events: none;
}
.disabled\:tw-cursor-not-allowed:disabled {
    cursor: not-allowed;
}
.disabled\:tw-opacity-50:disabled {
    opacity: 0.5;
}
.data-\[state\=checked\]\:tw-bg-primary[data-state="checked"] {
    background-color: hsl(var(--primary));
}
.data-\[state\=selected\]\:tw-bg-muted[data-state="selected"] {
    background-color: hsl(var(--muted));
}
.data-\[state\=checked\]\:tw-text-primary-foreground[data-state="checked"] {
    color: hsl(var(--primary-foreground));
}
.data-\[state\=open\]\:tw-animate-in[data-state="open"] {
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.data-\[state\=closed\]\:tw-animate-out[data-state="closed"] {
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[state\=closed\]\:tw-fade-out-0[data-state="closed"] {
    --tw-exit-opacity: 0;
}
.data-\[state\=open\]\:tw-fade-in-0[data-state="open"] {
    --tw-enter-opacity: 0;
}
.data-\[state\=closed\]\:tw-zoom-out-95[data-state="closed"] {
    --tw-exit-scale: .95;
}
.data-\[state\=open\]\:tw-zoom-in-95[data-state="open"] {
    --tw-enter-scale: .95;
}
.data-\[state\=closed\]\:tw-slide-out-to-left-1\/2[data-state="closed"] {
    --tw-exit-translate-x: -50%;
}
.data-\[state\=closed\]\:tw-slide-out-to-top-\[48\%\][data-state="closed"] {
    --tw-exit-translate-y: -48%;
}
.data-\[state\=open\]\:tw-slide-in-from-left-1\/2[data-state="open"] {
    --tw-enter-translate-x: -50%;
}
.data-\[state\=open\]\:tw-slide-in-from-top-\[48\%\][data-state="open"] {
    --tw-enter-translate-y: -48%;
}
@media (min-width: 640px) {
    .sm\:tw-mt-0 {
        margin-top: 0px;
    }
    .sm\:tw-flex-row {
        flex-direction: row;
    }
    .sm\:tw-justify-end {
        justify-content: flex-end;
    }
    .sm\:tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem * var(--tw-space-x-reverse));
        margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .sm\:tw-rounded-lg {
        border-radius: var(--radius);
    }
    .sm\:tw-text-left {
        text-align: left;
    }
}
.\[\&\:has\(\[role\=checkbox\]\)\]\:tw-pr-0:has([role=checkbox]) {
    padding-right: 0px;
}
.\[\&\>tr\]\:last\:tw-border-b-0:last-child>tr {
    border-bottom-width: 0px;
}
.\[\&_th\]\:tw-sticky th {
    position: sticky;
}
.\[\&_th\]\:tw-top-20 th {
    top: 5rem;
}
.\[\&_th\]\:tw-bg-muted th {
    background-color: hsl(var(--muted));
}
.\[\&_tr\:last-child\]\:tw-border-0 tr:last-child {
    border-width: 0px;
}
.\[\&_tr\]\:tw-border-b tr {
    border-bottom-width: 1px;
}

/* vietnamese */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8a63bc110e8f45ad-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/00045315ec24c208-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/42ca9a2dc174b9b9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a75fe934ca01b6d6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/70336daae8f9acb3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7ebd901f2f4a0b98-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ed0713aabc469750-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ace9c6b312d37d07-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/95a978e26cc29d74-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d4a6d1072ea531dd-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e1012b8d4e21a3f0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f5e5067cd50e2c82-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/b8ce78b8b9460bfe-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/af700d9cbf4b15b0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/0e7784537271ba44-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/f2bfb63acfc2a372-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/642cf3f5695072c2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Barlow_dc08d0';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/83c76cede88902c5-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Barlow_Fallback_dc08d0';src: local("Arial");ascent-override: 103.43%;descent-override: 20.69%;line-gap-override: 0.00%;size-adjust: 96.68%
}.__className_dc08d0 {font-family: '__Barlow_dc08d0', '__Barlow_Fallback_dc08d0', Helvetica, Arial, sans-serif;font-style: normal
}

